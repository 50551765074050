//
// Daterange
//

.daterangepicker {
    font-family: $font-family-base;
    border: $dropdown-border-width solid $dropdown-border-color;
    box-shadow: $box-shadow;
    background-color: $dropdown-bg;

    .calendar-table {
        border: 1px solid $dropdown-bg;
        background-color: $dropdown-bg;

        td,th {
            color: #{map-get($grays, "600")};
        }

        .next,
        .prev {
            span {
                border-color: #{map-get($grays, "500")};
            }
        }
    }   

    .ranges {
        li {
            &:hover {
                background-color: $dropdown-link-hover-bg;
            }
            &.active {
                background-color: $primary;
            }
        }
    }

    

    td {
        &.in-range {
            background-color: #{lighten(map-get($grays, "300"),5%)};
            color: #{map-get($grays, "700")};
        }
        &.off,
        &.off.end-date, 
        &.off.in-range,
        &.off.start-date {
            background-color: $dropdown-bg;
            color: $dropdown-link-color;
            opacity: 0.5;
        }
    
        &.active,&.active:hover{
            background-color: $primary;
            color: $white;
        }
    }

    td.available:hover, 
    th.available:hover,
    td.active,
    td.active:hover {
        background-color: $primary;
        color: $white;
    } 

    &:after {
        border-bottom: 6px solid $dropdown-bg;
    }
    &:before {
        border-bottom: 7px solid $dropdown-border-color;
    }

    .drp-buttons {
        border-top: 1px solid $dropdown-border-color;
    }

    select {
        &.ampmselect, 
        &.hourselect, 
        &.minuteselect, 
        &.secondselect {
            background: #{map-get($grays, "100")};
            border: 1px solid #{map-get($grays, "100")};
            color: $dropdown-link-color;
        }
    }

    &.show-ranges {
        .drp-calendar.left {
            border-left: 1px solid $dropdown-border-color;
        }
    }
}



// Dark mode
body[data-layout-color="dark"] {
    .daterangepicker {
        .calendar-table {
            td,th {
                color: #{map-get($dark-grays, "600")};
            }
    
            .next,
            .prev {
                span {
                    border-color: #{map-get($dark-grays, "500")};
                }
            }
        }
        td {
            &.in-range {
                background-color: #{lighten(map-get($dark-grays, "300"),5%)};
                color: #{map-get($dark-grays, "700")};
            }
        }
        select {
            &.ampmselect, 
            &.hourselect, 
            &.minuteselect, 
            &.secondselect {
                background: #{map-get($dark-grays, "100")};
                border: 1px solid #{map-get($dark-grays, "100")};
            }
        }
    }
}