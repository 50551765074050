// 
// maps.scss
//

//Google Maps

.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: #{map-get($grays, "100")};
  border-radius: 3px;
}


// Vector Map 

.jvectormap-label {
  border: none;
  background: #{map-get($grays, "800")};
  color: #{map-get($grays, "100")};
  font-family: $font-family-base;
  font-size: $font-size-base;
  padding: 5px 8px;
}


// zoomin & zoomout btn color
.jvectormap-container {
  .jvectormap-zoomin, .jvectormap-zoomout {
    background-color: #{map-get($grays, "800")};
    color: #{map-get($grays, "100")};
  }
}




// Dark mode
body[data-layout-color="dark"] {
  .gmaps,
  .gmaps-panaroma {
    background: #{map-get($dark-grays, "100")};
  }
  .jvectormap-label {
    border: none;
    background: #{map-get($dark-grays, "800")};
    color: #{map-get($dark-grays, "100")};
  }
  .jvectormap-container {
    .jvectormap-zoomin, .jvectormap-zoomout {
      background-color: #{map-get($dark-grays, "800")};
      color: #{map-get($dark-grays, "100")};
    }
  }
}